import React from "react"
import Meister from "../components/meister"

import Seo from "../components/Seo"
const Meisterseite = () => {
  return (
    <>
      <Meister />
    </>
  )
}

export default Meisterseite